import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import AvgRegistrationList from "./AvgRegistrationList";
import LastSoldCourseList from "./LastSoldCourseList";
import LatestCourseList from "./LatestCourseList";
import LatestOfferList from "./LatestOfferList";
import PastWabinarList from "./PastWabinarList";
import SalesBarChart from "./SalesBarChart";
import SalesComparisonChart from "./SalesComparisonChart";
import UpcomingWebinarList from "./UpcomingWebinarList";


export default function Dashboard() {
	// message to encrypt
	let msg = JSON.stringify({
		name: "text",
	});

	// the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
	let key = encHex.parse("0123456789abcdef0123456789abcdef");
	let iv = encHex.parse("abcdef9876543210abcdef9876543210");

	// encrypt the message
	let encrypted = aes
		.encrypt(msg, key, { iv: iv, padding: padZeroPadding })
		.toString();

	return (
		<div className="inner_right_section">
			{/* <SalesBarChart />
			<LastSoldCourseList /> */}
			<div className="row">
				<SalesComparisonChart />
				<AvgRegistrationList />
			</div>
			{/* <div className="row">
				<LatestCourseList />
			</div>
			<div className="row">
				<LatestOfferList />
				<PastWabinarList />
			</div>
			<UpcomingWebinarList /> */}

		</div>
	);
}
