import { useEffect } from "react";
import RouteMain from "./routes";


function App() {
  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <>
      <RouteMain />
    </>
  );
}

export default App;
