import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, CloseButton } from 'react-bootstrap'
import { ImSpinner2 } from 'react-icons/im'

function CustomModal(props) {
  return (
    <Modal show={props.show} size={props.size} onHide={() => props.onHide()}>
      {props.header !== undefined ? (
        <Modal.Header>
          <Modal.Title className="small">{props.header}</Modal.Title>
          <CloseButton variant="white"  onClick={() => props.onHide()}/>
        </Modal.Header>
      ) : (
        ''
      )}
      <Modal.Body>
        {props.body}
        {props.isform !== null && !props.isform ? (
          <div className='mt-3 text-end'>
            <Button
              className='btn-grad btn-secondary'
              disabled={props.loadingsave}
              onClick={props.onSubmit}
            >
              {props.loadingsave ? (
                <ImSpinner2 className='icon-spin' />
              ) : (
                'Yes'
              )}
            </Button>

            <Button
              variant='default'
              style={{ color: 'orange' }}
              onClick={props.onClose}
            >
              No
            </Button>
          </div>
        ) : (
          ''
        )}
        {props.isform !== null && props.onSubmit && props.isform ? (
          <div className='mt-3 text-end'>
            <Button
              variant='secondary'
              className='btn-grad'
              disabled={props.loadingsave}
              type='submit'
              onClick={props.onSubmit}
            >
              {props.loadingsave ? (
                <ImSpinner2 className='icon-spin' />
              ) : (
                'Save'
              )}
            </Button>
            <button className='btn default_cancel_btn' onClick={props.onClose}>
              Close
            </button>
          </div>
        ) : (
          ''
        )}
      </Modal.Body>

    </Modal>
  )
}

export default CustomModal
