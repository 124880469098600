import store from '../redux/store';
import { accountTypes } from '../redux/types/accountTypes';

const downloadData = async (url, type = 'GET', data = null) => {
    store.dispatch({
        type: accountTypes.REFRESH_LOGIN_TOKEN_RESET
    })

    const storeState = store.getState()

    let response = null, responseCode = null


    let options = {
        method: type,
        headers: {
            'Authorization': 'Bearer ' + storeState.accountState.token
        },
        body: data
    }

    try {
        response = await fetch(process.env.REACT_APP_API_LINK + url, options).then((r) => {
            responseCode = r.status
            if (r.status === 401) {
                store.dispatch({
                    type: accountTypes.REFRESH_LOGIN_TOKEN
                })
                throw new Error("401")
            }
            else if (r.status === 403) {
                store.dispatch({
                    type: accountTypes.ACCESS_DENIED
                })
                throw new Error("403")
            }
            else if (r.status === 422) {
                return r.json()
                // throw new Error("422")
            }
            else if (r.status === 200) {
                return r.blob()
            }
        })

        if (responseCode === 422) {
            store.dispatch({
                type: accountTypes.VALIDATION_ERROR,
                payload: response.data
            })
        }
    } catch (error) {
        response = null
    } finally {

        return {
            response: response,
            responseCode: responseCode
        }
    }
};

export default downloadData;