import { useEffect, useState, useRef } from "react";
import "../../assets/css/login.css";
import loginBanner from "../../assets/images/login_banner.png";
import dotBackground from "../../assets/images/dot-background-png.png";
import { BiUser, BiLockAlt } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
import fetchData from "../../helpers/fetchData";

import ThemeToaster from "../Utils/ThemeToaster";
import { useDispatch, useSelector } from "react-redux";
import { accountAction } from "../../redux/actions/accountAction";
import { useNavigate } from "react-router-dom";
import { toasterTypes } from "../../redux/types/toasterTypes";

export default function Login() {
	const [emailId, setEmailId] = useState();
	const [password, setPassword] = useState();
	const [showToast, setShowToast] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [loadingReSend, setLoadingReSend] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastType, setToastType] = useState();
	const state = useSelector((state) => state.accountState);
	const [secret, setSecret] = useState(false);

	const [loginSuccess, setLoginSuccess] = useState(false);
	const [otpSumbited, setOtpSumbited] = useState(false);
	const [firstOtpInput, setFirstOtpInput] = useState("");
	const [secondOtpInput, setSecondOtpInput] = useState("");
	const [thirdOtpInput, setThirdOtpInput] = useState("");
	const [fourthOtpInput, setFourthOtpInput] = useState("");

	const otpInputRef2 = useRef(null);
	const otpInputRef3 = useRef(null);
	const otpInputRef4 = useRef(null);

	let navigate = useNavigate();

	useEffect(() => {
		if (state.token !== null) {
			navigate("/dashboard");
		}
	}, [state.token]);

	const dispatch = useDispatch();

	const logmeIn = async (e) => {
		e.preventDefault();
		setLoadingSubmit(true);
		const r = await fetchData(
			"login",
			1,
			JSON.stringify({ email_id: emailId, password: password })
		);
		if (r.responseCode === 200) {
			setLoginSuccess(true);
			setSecret(r.response.secret);
		} else if(r.responseCode === 401){
			dispatch({
				type: toasterTypes.SHOW_TOAST,
				payload: {
					toast_type: 'error',
					toast_msg: "Invalid Credentials"
				}
			})
		}else{
			dispatch({
				type: toasterTypes.SHOW_TOAST,
				payload: {
					toast_type: 'error',
					toast_msg: r.response.msg
				}
			})
		}
		setLoadingSubmit(false);
	};

	const submitOTP = async (e) => {
		e.preventDefault();
		setOtpSumbited(true);
		const r = await fetchData(
			"post-otp-verify",
			1,
			JSON.stringify({
				secret: secret,
				otp:
					firstOtpInput +
					"" +
					secondOtpInput +
					"" +
					thirdOtpInput +
					"" +
					fourthOtpInput,
			})
		);
		if (r.responseCode === 200) {
			dispatch(
				accountAction.loginSuccess({
					access_token: r.response.access_token,
					secret: secret,
				})
			);
		} else {
			setToastText("Invalid OTP");
			setToastType("error");
			dispatch({
				type: toasterTypes.SHOW_TOAST,
				payload: {
					toast_type: 'error',
					toast_msg: "Invalid OTP"
				}
			})
			// setShowToast(true);
		}
		setOtpSumbited(false);
	};

	const gotoNextInput = (type, value, ref) => {
		if (value !== undefined && !value.match("^[0-9]*$")) {
			return false;
		}
		switch (type) {
			case 1:
				setFirstOtpInput(value);
				ref.current.focus();
				break;
			case 2:
				setSecondOtpInput(value);
				ref.current.focus();
				break;
			case 3:
				setThirdOtpInput(value);
				ref.current.focus();
				break;
			case 4:
				setFourthOtpInput(value);
				// /(ref)
				break;
			default:
				return;
		}
	};

	const Ref = useRef(null);

	const [timer, setTimer] = useState('00:00');

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		return {
			total, minutes, seconds
		};
	}

	const startTimer = (e) => {
		let { total, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {

			// update the timer
			// check if less than 10 then we need to 
			// add '0' at the beginning of the variable
			setTimer(
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}


	const clearTimer = (e) => {

		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next    
		setTimer('01:00');

		// If you try to remove this line the 
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);

		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if 
		// you entend to add more time
		deadline.setMinutes(deadline.getMinutes() + 1);
		// deadline.setSeconds(deadline.getSeconds() + 10);
		return deadline;
	}

	useEffect(() => {
		if (loginSuccess) {
			clearTimer(getDeadTime());
		}
	}, [loginSuccess])

	const reSend = async () => {
		setLoadingReSend(true);
		const r = await fetchData(
			"resend",
			1,
			JSON.stringify({ email_id: emailId })
		);
		if (r.responseCode === 200) {
			clearTimer(getDeadTime());
        } else {
			dispatch({
				type: toasterTypes.SHOW_TOAST,
				payload: {
					toast_type: 'error',
					toast_msg: r.response.msg
				}
			})
		}
		setLoadingReSend(false)
	}

	return (
		<section className="login_full_wrapper">
			<div className="background_overley">
				<img src={dotBackground} className="img-fluid" alt="" />
			</div>
			<div className="login_top_box">
				<div className="login_inner_box">
					<div className="row">
						<div className="col-lg-6">
							<p>
								<img
									alt=""
									src="https://d24uab5gycr2uz.cloudfront.net/uploads/white_theme/images/elm_logo.webp"
									className="img-fluid elm_logo"
								/>
							</p>
							<div className="text-center">
								<img alt="" src={loginBanner} className="img-fluid" />
							</div>
						</div>
						{loginSuccess === false && (
							<div className="col-lg-6">
								<p className="welcome_back">Login to continue</p>
								<small>all fields are required*</small>
								<form
									className="row mt-4 custom_login_form"
									onSubmit={(e) => logmeIn(e)}
								>
									<div className="col-11">
										<label className="visually-hidden" htmlFor="email">
											Username
										</label>
										<div className="input-group">
											<div className="input-group-text">
												<BiUser />
											</div>
											<input
												type="email"
												className="form-control"
												id="email"
												placeholder="Email"
												onChange={(e) => setEmailId(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="col-11">
										<label className="visually-hidden" htmlFor="password">
											Password
										</label>
										<div className="input-group">
											<div className="input-group-text">
												<BiLockAlt />
											</div>
											<input
												type="password"
												className="form-control"
												id="password"
												placeholder="Password"
												onChange={(e) => setPassword(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="col-11">
										<button
											type="submit"
											className="admin_btn mt-3 mb-2"
											disabled={loadingSubmit}
										>
											{!loadingSubmit ? (
												"Login"
											) : (
												<>
													<ImSpinner2 className="icon-spin" /> Loading
												</>
											)}
										</button>
									</div>
								</form>
							</div>
						)}
						{loginSuccess === true && (
							<div className="col-lg-6">
								<p className="welcome_back">Please Enter OTP</p>
								<form
									className="row mt-4 custom_login_form"
									onSubmit={(e) => submitOTP(e)}
								>
									<div className="col-lg-8">
										<div className="row">
											<div className="col-3">
												<div className="input-group">
													<input
														required
														type="text"
														autoFocus
														className="form-control otp_input"
														id="autoSizingInputGroup"
														value={firstOtpInput}
														maxLength={1}
														onChange={(e) =>
															gotoNextInput(1, e.target.value, otpInputRef2)
														}
													/>
												</div>
											</div>
											<div className="col-3">
												<div className="input-group">
													<input
														required
														type="text"
														className="form-control otp_input"
														id="autoSizingInputGroup"
														value={secondOtpInput}
														maxLength={1}
														onChange={(e) =>
															gotoNextInput(2, e.target.value, otpInputRef3)
														}
														ref={otpInputRef2}
													/>
												</div>
											</div>
											<div className="col-3">
												<div className="input-group">
													<input
														required
														type="text"
														className="form-control otp_input"
														id="autoSizingInputGroup"
														value={thirdOtpInput}
														maxLength={1}
														onChange={(e) =>
															gotoNextInput(3, e.target.value, otpInputRef4)
														}
														ref={otpInputRef3}
													/>
												</div>
											</div>
											<div className="col-3">
												<div className="input-group">
													<input
														required
														type="text"
														className="form-control otp_input"
														id="autoSizingInputGroup"
														value={fourthOtpInput}
														maxLength={1}
														onChange={(e) =>
															gotoNextInput(4, e.target.value, e)
														}
														ref={otpInputRef4}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="col-11">
										<button
											type="submit"
											className="admin_btn mt-3 mb-2"
											disabled={otpSumbited}
										>
											{!otpSumbited ? (
												"Verify"
											) : (
												<>
													<ImSpinner2 className="icon-spin" /> Loading
												</>
											)}
										</button>
										{timer === '00:00' ?
											<button
												type="button"
												className="admin_btn mt-3 mb-2 ms-2"
												disabled={loadingReSend}
												onClick={() => reSend()}
											>
												{!loadingReSend ? (
													"Resend"
												) : (
													<>
														<ImSpinner2 className="icon-spin" /> Loading
													</>
												)}
											</button> :
											<span className="mt-3 mb-2 ms-2">
											{timer}
											</span>
										}


									</div>
								</form>
							</div>
						)}
					</div>
				</div>
			</div>
			<ThemeToaster />
		</section>
	);
}
