import React from 'react'

const Error = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h1>Page isn't available</h1>
    </div>
  )
}

export default Error