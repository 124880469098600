import { initialState } from "../initialState";
import { toasterTypes } from "../types/toasterTypes";
/**
 * toast_type - success, info, error
 */
/**
 * initiate toast
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function toastReducer(state = initialState, action) {
    switch (action.type) {
        case toasterTypes.SHOW_TOAST:
            return {
                ...state,
                show_toast: true,
                toast_type: action.payload.toast_type,
                toast_msg: action.payload.toast_msg
            }
        case toasterTypes.CLOSE_TOAST:
            return {
                ...state,
                show_toast: false
            }
        default:
            return {
                ...state
            }
    }
}