import React, { useState } from 'react'
import { Line } from 'react-chartjs-2';
import fetchData from "../../helpers/fetchData";
import { TailSpin } from "react-loader-spinner";
import { Accordion } from 'react-bootstrap';
import 'chart.js/auto';
import { useSelector } from 'react-redux';


const SalesComparisonChart = () => {
    const [avgSales, setAvgSales] = useState(null);
    const state = useSelector((state) => state.accountState);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'X: Days, Y: Sale Amount',
            },
        },
    };

    const getAvgSale = async () => {
        const r = await fetchData('get-avg-sales-comparison', 2, null, true);
        if (r.responseCode === 200) {
            setAvgSales({
                labels: r.response.data.graph.map(data => data.days),
                datasets: [
                    {
                        label: r.response.data.month[0],
                        data: r.response.data.graph.map(data => data.current_month_value),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: r.response.data.month[1],
                        data: r.response.data.graph.map(data => data.last_month_value),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            });
        }
    };

    const call = () => {
        if (state.token !== null && avgSales === null) {
            getAvgSale();
        }
    }

    return (
        <div className="col-lg-6 mt-3">
            <Accordion onSelect={() => call()}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Sales Comparison Report</Accordion.Header>
                    <Accordion.Body>
                        {avgSales === null ? (
                            <div style={{textAlign: 'center', alignItems: 'center'}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <TailSpin color="#20273a" />
                                </div>
                            </div>
                        ) : (
                            <Line options={options} data={avgSales} />
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default SalesComparisonChart