import { Footer } from "./Footer";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { LoginRefresh } from "../Utils/LoginRefresh";
import { AccessDenied } from "../Utils/AccessDenied";
import ValidationError from "../Utils/ValidationError";
import ThemeToaster from "../Utils/ThemeToaster";

export const Layout = () => {
	const state = useSelector((state) => state.accountState);
	const navigate = useNavigate();
	useEffect(() => {
		if (state.token === null) {
			navigate("/login");
		}
	}, []);

	useEffect(() => {
		window.addEventListener("beforeunload", handleUnload);
		return () => {
			window.removeEventListener("beforeunload", handleUnload);
		};
	}, []);

	const handleUnload = (e) => {
		const message = "o/";
		(e || window.event).returnValue = message;
		return message;
	};

	const [leftMenuCloseMobile, setLeftMenuCloseMobile] = useState(false);

	return (
		<div className="full_wrapper">
			<div
				className={`top_wrapper ${
					leftMenuCloseMobile ? "close_left_menu" : ""
				}`}
			>
				<Header 
					onMobileMenuClick={setLeftMenuCloseMobile}
					value={leftMenuCloseMobile} 
				/>
				<section>
					<div className="container-fluid p-0 overflow-hidden">
						<LeftMenu
							token={state.token}
							onMobileMenuClick={setLeftMenuCloseMobile}
							value={leftMenuCloseMobile}
						/>
						<div className="right_section">
							<div className="recent_tabs">
								<ul className="recent_tab_list">
									<li>
										<a href="#">
											recent tabs 1
											<button className="close_tabs">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</a>
									</li>
									<li>
										<a href="#" className="active">
											recent tabs 2
											<button className="close_tabs">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</a>
									</li>
									<li>
										<a href="#">
											recent tabs 3
											<button className="close_tabs">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</a>
									</li>
									<li>
										<a href="#">
											recent tabs 4
											<button className="close_tabs">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</a>
									</li>
									<li>
										<a href="#">
											recent tabs 5
											<button className="close_tabs">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</a>
									</li>
								</ul>
							</div>
							<Outlet context={state} />
						</div>
					</div>
				</section>
				<LoginRefresh />
				<AccessDenied />
				<ValidationError />
				<Footer></Footer>
				<ThemeToaster />
			</div>
		</div>
	);
};
