import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap'
import fetchData from '../../helpers/fetchData'
import CustomModal from '../Utils/CustomModal'
import _ from "lodash";
import { FiInfo } from 'react-icons/fi';


const OrderBy = (props) => {
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [modalData, setModalData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const formRef = useRef(null);
    const inputRef = useRef(null);

    const checkValue = v => {
        if (v > 2147483647) {
            inputRef.current.value = 2147483647
        } else if (v < -2147483647) {
            inputRef.current.value = -2147483647
        } else {
            inputRef.current.value = v
        }
    }

    const handleAction = (id, name, rank) => {
        setModalData({
            id: id,
            rank: rank,
            name: 'Update Rank: ' + name.toUpperCase(),
        })
        setShowModal(true)
    }

    const handelSubmit = async (e) => {
        e.preventDefault();
        let d = _.cloneDeep(props.list)
        setLoadingSubmit(true)
        let formData = new FormData(formRef.current)
        formData.append('id', modalData.id)

        let r = await fetchData(props.url, 1, formData, true, false)

        if (r.responseCode === 200) {
            d.data.data.forEach((item) => {
                if (parseInt(item.id) === parseInt(formData.get('id'))) {
                    item[props.field] = formData.get(props.field);
                }
                props.setList(d)
            });
            setModalData(null)
            setShowModal(false)
        }
        setLoadingSubmit(false)
    }

    const handleClose = () => {
        setShowModal(false)
        setModalData(null)
    }

    const modalBody = () => {
        if (modalData !== null) {
            return (
                <form className="custom_form" ref={formRef}>
                    <div className="form-group row">
                        <input
                            required
                            type="number"
                            pattern="^-?\d$"
                            name={props.field}
                            className="form-control"
                            defaultValue={modalData !== null && modalData.rank}
                            onChange={(e) => checkValue(e.target.value)}
                            ref={inputRef}
                        />
                        {props.msg &&
                            <small className='text-info'>
                                Tip: {props.msg}
                            </small>
                        }
                    </div>
                </form>
            );
        }

        return null;
    }


    return (
        <>
            <Button variant="link" size="sm" onClick={() => handleAction(props.id, props.name, props.rank)}>{props.rank === null || props.rank === '' ? 0 : props.rank}</Button>

            <CustomModal
                size='sm'
                show={showModal}
                header={modalData !== null && modalData.name}
                onHide={handleClose}
                body={modalBody()}
                onClose={handleClose}
                onSubmit={(e) => handelSubmit(e)}
                isform={true}
                loadingsave={loadingSubmit}
            />

        </>
    );
};

export default OrderBy;