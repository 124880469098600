import { Container } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { accountAction } from "../../redux/actions/accountAction";
import Logo from "../../assets/images/logo.png";
import { BiUser } from "react-icons/bi";
import { BiMenu } from "react-icons/bi";
import { RiShutDownLine } from "react-icons/ri";
export const Header = (props) => {
	const state = useSelector((state) => state.accountState);
	let navigate = useNavigate();
	let dispatch = useDispatch();

	const logOut = () => {
		dispatch(
			accountAction.logout()
		);
		navigate('/login');
	}

	const setSearchOption = () => {
		let moduleArr = [];
		let i = 0;
		state.left_menu_data.data.map((cat) => {
			cat.modules.map((menu) => {
				moduleArr[i] = {
					label: menu.caption,
					value: menu.name,
					permission: menu.permission,
				};
				i++;
			});
		});
		return moduleArr;
	};
	const Biuser = (<BiUser className="icon" />);
	const onSearchSelect = (selectedModule) => {
		dispatch(
			accountAction.storeSelectedModulePermission(
				{ ...selectedModule.permission },
				selectedModule.value
			)
		);
		if (selectedModule.value === 'home') {
			navigate('/dashboard');

		} else {
			navigate(selectedModule.value + "/list");
		}
	};

	return (
		<header className="custom_nav">
			<Container fluid>
				<div className="row">
					<div className="col-lg-8 col-12 m-auto">
						<button
							className="menu_close close_left_menu"
							onClick={() => props.onMobileMenuClick(!props.value)}
						>
							<BiMenu />
						</button>

						<Link className="nav-link elm_logo" to={'/dashboard'}>
							<img src={Logo} alt="" className="img-fluid" />
						</Link>
						{state.left_menu_loaded && (
							<Select
								className='top_searchbar'
								placeholder={"Search Modules"}
								defaultValue={"Select Option"}
								options={setSearchOption()}
								onChange={(e) => onSearchSelect(e)}
							/>
						)}
						<button className="logout_admin">
							<RiShutDownLine className="icon" />
						</button>
					</div>
					<div className="col-lg-4 col-2 m-auto">
						<div className="user_info_header">
							<NavDropdown
								title={Biuser}
								className="user_menu"
								id="basic-nav-dropdown"
								style={{ alignSelf: "flex-end" }}

							>
								<NavDropdown.Item href="#action/3.1" onClick={() => logOut()}>
									<RiShutDownLine className="icon" /> Logout
								</NavDropdown.Item>

							</NavDropdown>
						</div>
					</div>
				</div>
			</Container>
		</header>
	);
};
