import React, { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import Select from "react-select";
import { Button } from "react-bootstrap";
import fetchData from "../../helpers/fetchData";
import downloadData from "../../helpers/downloadData";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

export default function DeferredReport() {
    const [downloadLoading, setdownloadLoading] = useState(false);
    const [types, setTypes] = useState(null);
    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [asOnDate, setAsOnDate] = useState(null);

    useEffect(() => {
        getSearchSupport();
    }, []);

    const getSearchSupport = async () => {
        let options = [];
        const r = await fetchData('get-deferred-search-list', 2, null, true);
        if (r.responseCode === 200) {
            Object.entries(r.response.data).forEach(type => {
                options.push({ value: type[0], label: type[1] });
            });
            setTypes(options);

        }
    };


    const handleInvoiceCSV = async (e) => {
        e.preventDefault();
        setdownloadLoading(true)
        let formData = new FormData();
        formData.append('item_type', type !== null ? type.value : type);
        formData.append('start_date', startDate !== null ? moment(startDate).format("Y-MM-DD") : null);
        formData.append('end_date', endDate !== null ? moment(endDate).format("Y-MM-DD") : null);
        formData.append('revenue_as_on_date', asOnDate !== null ? moment(asOnDate).format("Y-MM-DD") : null);

        const r = await downloadData('post-deferred-download', 'POST', formData)

        if (r.responseCode === 200) {
            const url = URL.createObjectURL(r.response);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "deferred_report_" + (type !== null ? type.label.split(' ').join('_').toLowerCase() : type) + "_" + (asOnDate !== null ? moment(asOnDate).format("Y_MM_DD") : null) + ".csv");
            document.body.appendChild(link);
            link.click();
        }
        setdownloadLoading(false)
    }



    return (
        <div className="inner_right_section">
            <div className="inner_right_content">
                <div className="row">
                    <div className="col-lg-8">
                        <p className="section_header">
                            Deferred Report / <small>Download</small>
                        </p>
                    </div>

                    <div className="col-lg-12">
                        <hr />
                    </div>
                    <div className="col-lg-12">
                        <div className="col-lg-8 ">
                            <form className="custom_form" onSubmit={(e) => handleInvoiceCSV(e)}>
                                <div className="search_box">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group row">
                                                <div className="col-lg-4 text-end">
                                                    <label className="form-label">Type :</label>
                                                </div>
                                                <div className="col-lg-8">
                                                    {types !== null ? (
                                                        <Select
                                                            required
                                                            isClearable={true}
                                                            options={types}
                                                            onChange={(t) => setType(t)}
                                                            maxMenuHeight="180px"
                                                            placeholder={"Select Type"}
                                                            className='select-color'
                                                        />
                                                    ) : (
                                                        <div className="mt-2 text-white">
                                                            Please wait..
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-lg-4 text-end">
                                                    <label className="form-label">Date Range :</label>
                                                </div>
                                                <div className="col-lg-4">
                                                    <DateTime
                                                        closeOnSelect
                                                        inputProps={{
                                                            readOnly: true,
                                                            required: true,
                                                            className: "not_readonly_control form-control",
                                                            placeholder: "Start Date"
                                                        }}
                                                        renderInput={(props) => {
                                                            return <input {...props} value={startDate !== null ? props.value : ''} />
                                                        }}
                                                        value={startDate !== null ? startDate : ''}
                                                        dateFormat="MMM, D, YYYY"
                                                        timeFormat={false}
                                                        onOpen={() => setStartDate(null)}
                                                        onChange={(date) => setStartDate(date.toDate())}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <DateTime
                                                        closeOnSelect
                                                        inputProps={{
                                                            readOnly: true,
                                                            required: true,
                                                            className: "not_readonly_control form-control",
                                                            placeholder: "End Date"
                                                        }}
                                                        renderInput={(props) => {
                                                            return <input {...props} value={endDate !== null ? props.value : ''} />
                                                        }}
                                                        value={endDate !== null ? endDate : ''}
                                                        dateFormat="MMM, D, YYYY"
                                                        timeFormat={false}
                                                        onOpen={() => setEndDate(null)}
                                                        onChange={(date) => setEndDate(date.toDate())}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-lg-4 text-end">
                                                    <label className="form-label">As on Date :</label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <DateTime
                                                        closeOnSelect
                                                        inputProps={{
                                                            readOnly: true,
                                                            required: true,
                                                            className: "not_readonly_control form-control",
                                                            placeholder: "As on Date"
                                                        }}
                                                        renderInput={(props) => {
                                                            return <input {...props} value={asOnDate !== null ? props.value : ''} />
                                                        }}
                                                        value={asOnDate !== null ? asOnDate : ''}
                                                        dateFormat="MMM, D, YYYY"
                                                        timeFormat={false}
                                                        onOpen={() => setAsOnDate(null)}
                                                        onChange={(date) => setAsOnDate(date.toDate())}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9 serch-align">
                                                <Button
                                                    variant="secondary"
                                                    className="btn-grad"
                                                    type="submit"
                                                    disabled={downloadLoading}
                                                >
                                                    {downloadLoading ? (
                                                        <ImSpinner2 className="icon-spin" />
                                                    ) : (
                                                        "Download"
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
