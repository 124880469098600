import React, { useEffect, useState } from "react";
import DynamicIcon from "../Utils/DynamicIcon";
import Logo from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import {
	BiChevronDown,
	BiChevronUp,
	BiListCheck,
	BiPlus,
} from "react-icons/bi";

import { useDispatch, useSelector } from "react-redux";
import { accountAction } from "../../redux/actions/accountAction";
import { BiMenu } from "react-icons/bi";


export default function LeftMenu({ token, onMobileMenuClick, value }) {
	const location = useLocation();

	const pathName = location.pathname.split("/");

	useEffect(() => {
		if (token) {
			getMenu(token);
		}

	}, []);

	const dispatch = useDispatch();

	const state = useSelector((state) => state.accountState);

	const getMenu = (token) => {
		dispatch(accountAction.getLeftMenu(token));
	};

	const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

	const hoverCategoryMenu = (e, activeCategoryIndex) => {
		setSelectedCategoryIndex(activeCategoryIndex);
		if (value) {
			onMobileMenuClick(!value)
		}
	};

	const [selectedModuleMenu, setSelectedModuleMenu] = useState();

	const moduleMenuClick = (moduleIndex) => {
		if (moduleIndex === selectedModuleMenu) {
			setSelectedModuleMenu(null);
		} else {
			setSelectedModuleMenu(moduleIndex);
		}
	};


	const clickMenu = (permission, selected_menu_name) => {
		dispatch(
			accountAction.storeSelectedModulePermission(
				permission,
				selected_menu_name
			)
		);
		if (window.innerWidth <= 800 && window.innerHeight <= 600) {
			onMobileMenuClick(!value)
		}
	};

	return (
		<div className="menu_wrapper">
			<div className="left_menu">
				<ul className="main_menu">
					<li>
						<button
							className="menu_close"
							onClick={() => onMobileMenuClick(!value)}
						>
							<BiMenu />
						</button>
					</li>
					{state.left_menu_loaded &&
						state.left_menu_data.data.map((menuCat, categoryMenuIndex) => (
							<li key={categoryMenuIndex}>
								{window.innerWidth <= 800 && window.innerHeight <= 600 ?
									<span
										className={`top_menu_list ${selectedCategoryIndex === categoryMenuIndex ? "active" : ""
											}`}
										onClick={(e) => hoverCategoryMenu(e, categoryMenuIndex)}
									>
										<DynamicIcon
											icon={menuCat.icon}
											className="left_menu_category_level"
										/>
									</span>
									:

									<span
										className={`top_menu_list ${selectedCategoryIndex === categoryMenuIndex ? "active" : ""
											}`}
										onMouseOver={(e) => hoverCategoryMenu(e, categoryMenuIndex)}
									>
										<DynamicIcon
											icon={menuCat.icon}
											className="left_menu_category_level"
										/>
									</span>
								}

								{selectedCategoryIndex === categoryMenuIndex && (
									<div className="menu_dropdown">
										<p className="category_name">{menuCat.name}</p>
										<ul className="upper_sub_menu">
											<li>
												{menuCat.modules.map((innerMenu, innerMenuIndex) => (
													<React.Fragment key={innerMenuIndex}>
														<span
															className={`menu_list ${innerMenuIndex === selectedModuleMenu
																? "active"
																: ""
																}`}
															onClick={() => moduleMenuClick(innerMenuIndex)}
														>
															<span>
																{innerMenu.caption}
																{innerMenuIndex === selectedModuleMenu ? (
																	<BiChevronUp className="icon" />
																) : (
																	<BiChevronDown className="icon" />
																)}
															</span>
														</span>
														{innerMenuIndex === selectedModuleMenu && (
															<ul className="sub_dropdown floating_menu">
																{innerMenu.permission.perm_view && (
																	<li>
																		<Link
																			to={`/${innerMenu.name}/list`}
																			className={`sub_menu_list ${innerMenu.name === pathName[1] &&
																				pathName[pathName.length - 1] !== "add"
																				? "active"
																				: ""
																				}`}
																			onClick={() =>
																				clickMenu(
																					innerMenu.permission,
																					innerMenu.name
																				)
																			}
																		>
																			<BiListCheck className="icon" />
																			List
																		</Link>
																	</li>
																)}
																{innerMenu.permission.perm_insert && (
																	<li>
																		<Link
																			to={`/${innerMenu.name}/add`}
																			className={`sub_menu_list ${innerMenu.name === pathName[1] &&
																				pathName[pathName.length - 1] === "add"
																				? 'active'
																				: ''
																				}`}
																		>
																			<BiPlus className="icon" />
																			Add New
																		</Link>
																	</li>
																)}
															</ul>
														)}
													</React.Fragment>
												))}
											</li>
										</ul>
									</div>
								)}
							</li>
						))}
				</ul>
				<Link className="nav-link elm_logo_mobile" to={'/dashboard'}>
					<img src={Logo} alt="" className="img-fluid" />
				</Link>
			</div>
		</div>
	);
}
