import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import fetchData from "../../helpers/fetchData";
import { TailSpin } from "react-loader-spinner";
import { Accordion } from 'react-bootstrap';
import 'chart.js/auto';
import { useSelector } from 'react-redux';


const AvgRegistrationList = () => {
    const [avgRegistration, setAvgRegistration] = useState(null);
    const state = useSelector((state) => state.accountState);
    const [total, setTotal] = useState(null);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Registration: ' + total + ' Peoples',
            },
        },
    };

    const getAvgSale = async () => {
        const r = await fetchData('get-avg-registration', 2, null, true);
        if (r.responseCode === 200) {
            setTotal(r.response.data.total)
            setAvgRegistration({
                labels: ['Verified %', 'Not verified %'],
                datasets: [
                    {
                        label: ['Verified %', 'Not verified %'],
                        data: [r.response.data.verified, r.response.data.not_verified],
                        backgroundColor: ['#6dc5a3', '#788ba0', '#414e62']
                    }
                ],
            });
        }
    };

    const call = () => {
        if (state.token !== null && avgRegistration === null) {
            getAvgSale();
        }
    }

    return (
        <div className="col-lg-6 mt-3">
            <Accordion onSelect={() => call()}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Registration Data</Accordion.Header>
                    <Accordion.Body>
                        {avgRegistration === null ? (
                            <div style={{textAlign: 'center', alignItems: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <TailSpin color="#20273a" />
                                </div>
                            </div>
                        ) : (
                            
                            <Doughnut width={250} height={250} options={options} data={avgRegistration} />
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default AvgRegistrationList