import store from '../redux/store';
import { accountTypes } from '../redux/types/accountTypes';
import { toasterTypes } from '../redux/types/toasterTypes';

const fetchData = async (url, method_type = 1, data = null, token = null, headerType = 'json') => {
    store.dispatch({
        type: accountTypes.REFRESH_LOGIN_TOKEN_RESET
    })

    const storeState = store.getState()

    let response = null, responseCode = null

    let headers = {
        'Content-Type': 'application/json'
    }

    if (token) {
        headers = {
            'Authorization': 'Bearer ' + storeState.accountState.token
        }
    }
    if (headerType === 'json' && token) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + storeState.accountState.token
        }
    }


    let options

    if (method_type === 2) {
        options = {
            method: "GET",
            headers,
            credentials: "include"
        }
    } else if (method_type === 1){
        options = {
            method: "POST",
            headers,
            body: data,
            credentials: "include"
        }
    } else if(method_type===3){
        options = {
            method: "DELETE",
            headers,
            body: data,
            credentials: "include"
        }
    }

    try {
        response = await fetch(process.env.REACT_APP_API_LINK + url, options).then((r) => {
            responseCode = r.status

            if (r.status === 401) {
                store.dispatch({
                    type: accountTypes.REFRESH_LOGIN_TOKEN
                })
                throw new Error("401")
            }
            else if (r.status === 403) {
                store.dispatch({
                    type: accountTypes.ACCESS_DENIED
                })
                throw new Error("403")
            }
            else if (r.status === 422) {
                return r.json()
                // throw new Error("422")
            }
            else if (r.status === 200) {

                return r.json()
            }
        })

        if (responseCode === 422) {
            store.dispatch({
                type: accountTypes.VALIDATION_ERROR,
                payload: response.data
            })
        }
        if (responseCode === 200 && (method_type === 1 || method_type === 3)) {
            //for post only
            store.dispatch({
                type: toasterTypes.SHOW_TOAST,
                payload: {
                    toast_type: 'success',
                    toast_msg: response.msg
                }
            })
        }
    } catch (error) {
        response = null
    } finally {
        return {
            response: response,
            responseCode: responseCode
        }
    }
}

export default fetchData