export const accountTypes = {
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL',
    LEFT_MENU_LOADING: 'LEFT_MENU_LOADING',
    LEFT_MENU_LOADING_COMPLETE: 'LEFT_MENU_LOADING_COMPLETE',
    REFRESH_LOGIN_TOKEN: 'REFRESH_LOGIN_TOKEN',
    LOGIN_TOKEN_REFRESHED: 'LOGIN_TOKEN_REFRESHED',
    SELECTED_MODULE_PERMISSION: 'SELECTED_MODULE_PERMISSION',
    ACCESS_DENIED: 'ACCESS_DENIED',
    ACCESS_GRANTED: 'ACCESS_GRANTED',
    CURRENT_ACTIVE_PAGE_NO: 'CURRENT_ACTIVE_PAGE_NO',
    CURRENT_SUB_ACTIVE_PAGE_NO: 'CURRENT_SUB_ACTIVE_PAGE_NO',
    REFRESH_LOGIN_TOKEN_RESET: 'REFRESH_LOGIN_TOKEN_RESET',
    CURRENT_ACTIVE_SEACH: 'CURRENT_ACTIVE_SEACH',
    VALIDATION_ERROR:'VALIDATION_ERROR'
}