import React from 'react';
import AsyncSelect from "react-select/async";

const SearchableSelect = (props) => {
    let timer;

    const loadOptions = (input) => new Promise((resolve) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            resolve(props.loadOptions(input));
        }, 500);
    });


    return (
        <AsyncSelect
            isClearable={props.isClear === false ? false : true}
            isMulti={props.isMulti === true ? true : false}
            noOptionsMessage={() => props.noOptionsMessage}
            loadingMessage={() => 'Searching...'}
            loadOptions={loadOptions}
            defaultOptions={[]}
            value={props.value !== null ? props.value : ''}
            getOptionLabel={(item) => props.isName === true ? item.name : (item.label !== undefined ? item.label : (item.isd_code !== undefined ? item.sortname + '(' + item.isd_code + ')' : (item.code !== undefined ? item.name + ' | ' + item.code : (item.title !== undefined ? item.title : item.name))))}
            getOptionValue={(item) => props.isName === true ? item.id : (item.value !== undefined ? item.value : (item.isd_code !== undefined ? item.isd_code : item.id))}
            onChange={(v) => props.onSelect(v)}
            maxMenuHeight="180px"
            placeholder={props.placeholder}
            className='select-color'
            isDisabled={props.isDisabled === true ? true : false}
            ref={props.itemRef}
        />
    );

};

export default SearchableSelect;