export const initialState = {
    is_logged_in: false,
    token: null,
    secret: null,
    loading_left_menu: true,
    left_menu_loaded: false,
    left_menu_data: [],
    current_active_page_no: 1,
    current_sub_active_page_no: 1,
    search_params: {}
}