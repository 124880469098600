import fetchData from "../../helpers/fetchData"
import { accountTypes } from "../types/accountTypes"

const logout = () => {
    return {
        type: accountTypes.LOGOUT,
        payload: null
    }
}

const loginSuccess = (data) => {

    return {
        type: accountTypes.LOGIN_SUCCESSFUL,
        payload: data
    }
}

const getLeftMenu = (token) => {
    return async (dispatch) => {
        dispatch(loadingLeftMenu())
        const response = await fetchData("get-module-category-module-list", 2, null, token);
        dispatch(loadingLeftMenuComplete(response.response))
    }
}

const loadingLeftMenu = () => {
    return {
        type: accountTypes.LEFT_MENU_LOADING
    }
}
const loadingLeftMenuComplete = (data) => {
    return {
        type: accountTypes.LEFT_MENU_LOADING_COMPLETE,
        payload: data
    }
}

const refreshLoginToken = () => {
    return {
        type: accountTypes.REFRESH_LOGIN_TOKEN
    }
}

const refreshLoginTokenComplete = (token) => {
    return {
        type: accountTypes.LOGIN_TOKEN_REFRESHED,
        payload:token
    }
}
const storeSelectedModulePermission = (permission, selected_menu_name) => {
    return {
        type: accountTypes.SELECTED_MODULE_PERMISSION,
        payload: {
            permission: permission,
            selected_menu_name: selected_menu_name
        }
    }
}
export const accountAction = {
    logout,
    loginSuccess,
    getLeftMenu,
    refreshLoginToken,
    refreshLoginTokenComplete,
    storeSelectedModulePermission
}