import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { accountTypes } from "../../redux/types/accountTypes";

export default function ValidationError() {
	const dispatch = useDispatch();

	const loginState = useSelector((state) => state.accountState);

	const closeValidationModal = () => {
		dispatch({
			type: accountTypes.REFRESH_LOGIN_TOKEN_RESET,
		});
	};

	return (
		<Modal
			show={loginState.validation_error}
			onHide={() => closeValidationModal()}
			className="error_modal"
		>

			<Modal.Body>
				<p className="error_occurred">
					Error occurred!!
				</p>
				{loginState.validation_error &&
					Object.keys(loginState.validation_data).map((item, i) => (
						<p key={i}>{loginState.validation_data[item]}</p>
					))}
				<button className="btn default_cancel_btn" onClick={() => closeValidationModal()}>Close</button>
			</Modal.Body>
		</Modal>
	);
}
