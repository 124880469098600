export const Footer = () => {
	const d = new Date();
	let year = d.getFullYear();
	return (
		<footer className="custom_footer">
			<div className="custom_inner_footer">
				<p>{year} © Elearnmarkets</p>
			</div>
		</footer>
	);
};
