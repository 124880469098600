import { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchData from "../../helpers/fetchData";
import { accountAction } from "../../redux/actions/accountAction";

export const LoginRefresh = () => {
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const [password, setPassword] = useState("");

	const loginState = useSelector((state) => state.accountState);

	const loginAgain = async (e) => {
		e.preventDefault();
		const r = await fetchData(
			"login",
			1,
			JSON.stringify({ secret: loginState.secret, password: password })
		);
		dispatch(accountAction.refreshLoginTokenComplete(r.response.access_token));
		navigate("/dashboard");
	};

	return (
		<Modal show={loginState.token_expired}>
			<Modal.Header>
				<Modal.Title>Please login again</Modal.Title>
			</Modal.Header>
			<Form className="custom_form" onSubmit={(e) => loginAgain(e)}>
				<Modal.Body>
					<input
						type={"password"}
						onChange={(e) => setPassword(e.target.value)}
						placeholder="Please type your password again"
						className="form-control"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn-grad" variant="secondary" type="submt">
						Save Changes
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
