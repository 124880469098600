import { initialState } from '../initialState'
import { accountTypes } from '../types/accountTypes'

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case accountTypes.LOGOUT: {
      return {
        ...state,
        is_logged_in: false,
        token: null,
        secret: null,
      }
    }
    case accountTypes.LOGIN_SUCCESSFUL: {
      return {
        ...state,
        is_logged_in: true,
        token: action.payload.access_token,
        secret: action.payload.secret,
      }
    }
    case accountTypes.LEFT_MENU_LOADING:
      return {
        ...state,
        loading_left_menu: true,
        left_menu_loaded: false,
      }
    case accountTypes.LEFT_MENU_LOADING_COMPLETE:
      return {
        ...state,
        loading_left_menu: false,
        left_menu_loaded: true,
        left_menu_data: action.payload,
      }
    case accountTypes.REFRESH_LOGIN_TOKEN:
      return {
        ...state,
        token_expired: true,
      }
    case accountTypes.REFRESH_LOGIN_TOKEN_RESET:
      return {
        ...state,
        token_expired: false,
        validation_error: false,
      }
    case accountTypes.LOGIN_TOKEN_REFRESHED:
      return {
        ...state,
        token_expired: false,
        token: action.payload,
      }
    case accountTypes.SELECTED_MODULE_PERMISSION:
      return {
        ...state,
        selected_module_permission: action.payload.permission,
        selected_module_name: action.payload.selected_menu_name,
        current_active_page_no: 1,
        current_sub_active_page_no: 1,
        search_params: {}
      }
    case accountTypes.ACCESS_DENIED:
      return {
        ...state,
        access_denied: true,
      }
    case accountTypes.ACCESS_GRANTED:
      return {
        ...state,
        access_denied: false,
      }
    case accountTypes.CURRENT_ACTIVE_PAGE_NO:
      return {
        ...state,
        current_active_page_no: action.payload,
      }
    case accountTypes.CURRENT_SUB_ACTIVE_PAGE_NO:
      return {
        ...state,
        current_sub_active_page_no: action.payload,
      }
    case accountTypes.CURRENT_ACTIVE_SEACH:
      return {
        ...state,
        search_params: action.payload,
      }
    case accountTypes.VALIDATION_ERROR:
      return {
        ...state,
        validation_error: true,
        validation_data: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
