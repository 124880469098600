import React from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { accountTypes } from "../../redux/types/accountTypes";

export const AccessDenied = () => {

	const dispatch = useDispatch()

	const loginState = useSelector((state) => state.accountState)

	const closeModal = () => {
		dispatch({
			type: accountTypes.ACCESS_GRANTED
		})
	}

	return (
		<Modal show={loginState.access_denied} onHide={() => closeModal()}>
			<Modal.Header>
				<Modal.Title>Access Denied!!</Modal.Title>
				<CloseButton variant="white" onClick={() => closeModal()} />
			</Modal.Header>
			<Modal.Body>You are not allowed to access this page</Modal.Body>
			<Modal.Footer>
				<Button className="btn-grad btn-secondary" onClick={() => closeModal()}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};
