import { useEffect, useState, useRef } from 'react'
import { BiRefresh, BiArrowBack, BiSearch, BiLinkExternal } from 'react-icons/bi'
import { Button, Pagination, Dropdown } from 'react-bootstrap'
import { ImSpinner2 } from 'react-icons/im'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { BsArrowDownShort, BsArrowLeft, BsArrowRight, BsArrowUpShort } from 'react-icons/bs'
import { accountTypes } from '../../redux/types/accountTypes'
import fetchData from '../../helpers/fetchData'
import { useParams, useNavigate } from 'react-router-dom'
import DateTime from "react-datetime"
import 'react-datetime/css/react-datetime.css'
import moment from "moment"
import CustomModal from '../Utils/CustomModal'
import OrderBy from '../Utils/OrderBy'
import Select from "react-select"
import _ from 'lodash'
import SearchableSelect from '../Utils/SearchableSelect'

const CourseList = () => {
  const accountState = useSelector((state) => state.accountState)
  const [nextCall, setNextCall] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataList, setDataList] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [courseId, setCourseId] = useState(null);
  const [subject, setSubject] = useState(null);
  const [language, setLanguage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isAddedTo999, setIsAddedTo999] = useState(null);
  const [isCertificateAvailable, setIsCertificateAvailable] = useState(null);
  const [isPromoCourse, setIsPromoCourse] = useState(null);
  const [isFeaturedCourse, setIsFeaturedCourse] = useState(null);
  const [activeInApp, setActiveInApp] = useState(null);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const [languageData, setLanguageData] = useState(null)
  const [subjectData, setSubjectData] = useState(null)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [sorting, setSorting] = useState({ field: 'Order By', ascending: true })
  const [discOption, setDiscOption] = useState(null)

  const [tags, setTags] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [loadingSave, setloadingSave] = useState(false)

  const [modalData, setModalData] = useState(null)
  const [saleTag, setSaleTag] = useState(null)

  let formRef = useRef(null)
  let courseRef = useRef(null);
  let formDiscardRef = useRef(null)
  let formTagRef = useRef(null)
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.id === '1') {
      getSaleTagData()
      getSubjectData()
      getLanguageData()
    }
  }, [])

  useEffect(() => {
    if (nextCall) {
      setCurrentPageNo(1)
    } else {
      getCourseList(accountState.current_active_page_no)
    }

    return () => setNextCall(true)

  }, [nextCall, sorting.ascending]);

  useEffect(() => {
    if (refresh === true && courseId === null && subject === null && language === null && status === null && isActive === null && isAddedTo999 === null && isCertificateAvailable === null && isPromoCourse === null && isFeaturedCourse === null && activeInApp === null && startDate === null && endDate === null) {
      setCurrentPageNo(1)
    }

    return () => setRefresh(false)

  }, [refresh, courseId, subject, language, status, isActive, isAddedTo999, isCertificateAvailable, isPromoCourse, isFeaturedCourse, activeInApp, startDate, endDate]);

  const getSaleTagData = async () => {
    let options = [];
    const r = await fetchData('get-course-sale-tag-create', 2, {}, true)

    if (r.responseCode === 200) {
      Object.entries(r.response.data).forEach(tag => {
        options.push({ value: tag[0], label: tag[1] });
      });
      setTags(options)
    }
  }

  const statusData = [
    { value: 1, label: 'Draft' },
    { value: 2, label: 'Pending' },
    { value: 3, label: 'Approved' },
  ]

  const discardOption = [
    { value: 'intimate', label: 'Intimate' },
    { value: 'discard', label: 'Discard' },
  ]

  const applySorting = (f, v) => {
    if (f === sorting.field) {
      setSorting({ field: sorting.field, ascending: v });
    }
  }

  const getCourseList = async (pageNo = 1) => {
    setPageLoading(true)
    let url
    let jsonData = null
    if (params.id === '1') {
      url = 'post-course-search?page=' + pageNo
      jsonData = JSON.stringify({
        id: courseId,
        course_subject_id: subject !== null ? parseInt(subject.id) : null,
        course_language_id: language !== null ? parseInt(language.id) : null,
        status: status !== null ? parseInt(status.value) : null,
        is_active: isActive !== null ? isActive.value : null,
        is_999: isAddedTo999 !== null ? isAddedTo999.value : null,
        certificate_available: isCertificateAvailable !== null ? isCertificateAvailable.value : null,
        is_promo_course: isPromoCourse !== null ? isPromoCourse.value : null,
        is_featured: isFeaturedCourse !== null ? isFeaturedCourse.value : null,
        active_in_app: activeInApp !== null ? activeInApp.value : null,
        start_date: startDate !== null ? moment(startDate).format("Y-MM-DD") : null,
        end_date: endDate !== null ? moment(endDate).format("Y-MM-DD") : null,
        asc: sorting.ascending,
      })
    } else if (params.id === '2') {
      url = 'post-course-version-search?page=' + pageNo
      jsonData = JSON.stringify({
        id: courseId,
        asc: sorting.ascending
      })
    }
    const r = await fetchData(url, 1, jsonData, true)
    if (r.responseCode === 200) {
      setDataList(r.response)
    }
    setPageLoading(false)
  }

  const getSubjectData = async () => {
    const r = await fetchData(
      'post-course-subject-suggestion-for-course',
      1,
      null,
      true
    )
    if (r.responseCode === 200) {
      setSubjectData(r.response.data)
    }
  }

  const getLanguageData = async () => {
    const r = await fetchData(
      'post-course-language-suggestion-for-course',
      1,
      null,
      true
    )
    if (r.responseCode === 200) {
      setLanguageData(r.response.data)
    }
  }

  const getCourseSuggestion = async (search_text) => {
    let url = params.id === '1' ? 'post-course-suggestion-for-course' : 'post-course-version-suggestion-for-course';
    const r = await fetchData(
      url,
      1,
      JSON.stringify({ search_text: search_text }),
      true
    );
    if (r.responseCode === 200) {
      return r.response.data;
    }
  };

  const setCurrentPageNo = (pageNo) => {
    dispatch({
      type: accountTypes.CURRENT_ACTIVE_PAGE_NO,
      payload: pageNo,
    })
    getCourseList(pageNo)
  }

  const checkPermission = (permission_to_check) => {
    const permissions = accountState.selected_module_permission
    return permissions[permission_to_check]
  }

  const search = async (e) => {
    e.preventDefault()
    setLoadingSearch(true)
    await getCourseList()
    setLoadingSearch(false)
  }

  const pageChange = (page_label) => {
    if (dataList.data.current_page === 1 && page_label === '&laquo; Previous') {
      return
    }

    if (dataList.data.current_page === dataList.data.last_page && page_label === 'Next &raquo;') {
      return
    }

    if (dataList.data.current_page === parseInt(page_label)) {
      return
    }

    if (page_label === 'Next &raquo;') {
      page_label = parseInt(dataList.data.current_page) + 1
    }

    if (page_label === '&laquo; Previous') {
      page_label = parseInt(dataList.data.current_page) - 1
    }

    setCurrentPageNo(page_label)
  }

  const tableHeadings = () => {
    let arr;
    switch (params.id) {
      case '1':
        arr = [
          'Order By',
          'Product ID',
          'Author',
          'Name',
          'Subject',
          'Mode',
          'Language',
          'Sale Tag',
          'Price',
          'Action',
        ]
        break;
      case '2':
        arr = [
          'Order By',
          'Name',
          'Requested By',
          'Requested Date',
          'Action',
        ]
        break;
      default:
        arr = []
        break;
    }

    return arr;
  }

  const handleClose = () => {
    setShowModal(false)
    setSaleTag(null)
    setModalData(null)
    setDiscOption(null)
  }

  const handelAction = (i, param) => {
    let obj;
    switch (param) {
      case 'not_for_sale':
        obj = {
          index: i,
          header: dataList.data.data[i].not_for_sale !== true ? 'Add This Course To Promo Course?' : 'Remove from Promo Course?',
          type: 'not_for_sale',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_paid_extension':
        obj = {
          index: i,
          header: dataList.data.data[i].is_paid_extension !== true ? 'Add This Course To Paid Extension?' : 'Remove from Paid Extension?',
          type: 'is_paid_extension',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_featured':
        obj = {
          index: i,
          header: dataList.data.data[i].is_featured !== true ? 'Add This Course To Featured?' : 'Remove from Featured?',
          type: 'is_featured',
          size: 'sm',
          isform: false,
        }
        break;
      case 'active_in_app':
        obj = {
          index: i,
          header: dataList.data.data[i].active_in_app !== true ? 'Add This Course To App?' : 'Remove from App?',
          type: 'active_in_app',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_active':
        obj = {
          index: i,
          header: dataList.data.data[i].is_active !== true ? 'Activate this course?' : 'Deactivate this course?',
          type: 'is_active',
          size: 'sm',
          isform: false,
        }
        break;
      case 'status':
        obj = {
          index: i,
          header: dataList.data.data[i].status === 2 ? 'Approve this course?' : 'Disapprove this course?',
          type: 'status',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_available_stockedge_club':
        obj = {
          index: i,
          header: dataList.data.data[i].is_available_stockedge_club !== true ? 'Add in SE Club?' : 'Remove from SE Club?',
          type: 'is_available_stockedge_club',
          size: 'sm',
          isform: false,
        }
        break;
      case 'certificate_available':
        obj = {
          index: i,
          header: dataList.data.data[i].certificate_available !== true ? 'Add certificate to this Course?' : 'Remove certificate from this course',
          type: 'certificate_available',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_999':
        obj = {
          index: i,
          header: dataList.data.data[i].is_999 !== true ? 'Add This Course To 999?' : 'Remove from 999?',
          type: 'is_999',
          size: 'sm',
          isform: false,
        }
        break;
      case 'is_need_clear_test':
        obj = {
          index: i,
          header: dataList.data.data[i].is_need_clear_test !== true ? 'Test needed' : 'Test not needed',
          type: 'is_need_clear_test',
          size: 'sm',
          isform: false,
        }
        break;
      case 'tag':
        obj = {
          index: i,
          header: dataList.data.data[i].sale_tag === null ? 'Add Sale Tag' : 'Manage Sale Tag',
          type: 'tag',
          size: 'md',
          isform: true,
        }
        setSaleTag(tags.filter(tag => tag.label === dataList.data.data[i].sale_tag)[0]);
        break;
      case 'approve':
        obj = {
          index: i,
          header: 'Approve course?',
          type: 'approve',
          size: 'sm',
          isform: false,
        }
        break;
      case 'discard':
        obj = {
          index: i,
          header: 'Discard Course?',
          type: 'discard',
          size: 'sm',
          isform: true,
        }
        break;
      case 'more':
        obj = {
          index: i,
          header: 'More Options',
          type: 'more',
          size: 'lg',
          isform: null,
        }
        break;
      default:
        obj = null
        break;
    }

    setModalData(obj)
    setShowModal(true)
  }

  const handelSubmit = async (e) => {
    e.preventDefault()
    setloadingSave(true)
    let url = '';
    let res = '';
    let d = _.cloneDeep(dataList.data)
    let formData = new FormData();

    switch (modalData.type) {
      case 'tag':
        url = `post-course-sale-tag-update/${d.data[modalData.index].id}`
        formData.append('sale_tag', saleTag !== null ? saleTag.value : null);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'approve':
        url = `get-course-version-approve/${d.data[modalData.index].id}`
        res = await fetchData(url, 2, null, true)
        break;
      case 'discard':
        url = `post-course-version-discard/${d.data[modalData.index].id}`
        formData.append('action', discOption !== null ? discOption.value : null);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_999':
        url = `post-course-999-update/${d.data[modalData.index].id}`
        formData.append('is_999', !d.data[modalData.index].is_999);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'certificate_available':
        url = `post-course-available-certificate-update/${d.data[modalData.index].id}`
        formData.append('certificate_available', !d.data[modalData.index].certificate_available);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_need_clear_test':
        url = `post-course-need-test-update/${d.data[modalData.index].id}`
        formData.append('is_need_clear_test', !d.data[modalData.index].is_need_clear_test);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'not_for_sale':
        url = `post-course-not-for-sale-update/${d.data[modalData.index].id}`
        formData.append('not_for_sale', !d.data[modalData.index].not_for_sale);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_paid_extension':
        url = `post-course-is-paid-extension-update/${d.data[modalData.index].id}`
        formData.append('is_paid_extension', !d.data[modalData.index].is_paid_extension);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_featured':
        url = `post-course-featured-update/${d.data[modalData.index].id}`
        formData.append('is_featured', !d.data[modalData.index].is_featured);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'active_in_app':
        url = `post-course-active-in-app-update/${d.data[modalData.index].id}`
        formData.append('active_in_app', !d.data[modalData.index].active_in_app);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_active':
        url = `post-course-activate-update/${d.data[modalData.index].id}`
        formData.append('is_active', !d.data[modalData.index].is_active);
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'status':
        url = `post-course-status/${d.data[modalData.index].id}`
        formData.append('status', (d.data[modalData.index].status === 3 ? 2 : 3));
        res = await fetchData(url, 1, formData, true, null)
        break;
      case 'is_available_stockedge_club':
        url = `post-course-stockedge-approve/${d.data[modalData.index].id}`
        formData.append('is_available_stockedge_club', !d.data[modalData.index].is_available_stockedge_club);
        res = await fetchData(url, 1, formData, true, null)
        break;
    }

    if (res.responseCode === 200) {
      if (modalData.type === 'tag') {
        d.data[modalData.index].sale_tag = tags.filter(tag => tag.value === formData.get("sale_tag"))[0].label;
        setDataList({ data: d })
      } else if (modalData.type === 'is_999' || modalData.type === 'certificate_available' || modalData.type === 'is_need_clear_test' || modalData.type === 'not_for_sale' || modalData.type === 'is_paid_extension' || modalData.type === 'is_featured' || modalData.type === 'active_in_app' || modalData.type === 'is_active' || modalData.type === 'status' || modalData.type === 'is_available_stockedge_club') {
        changeAction(modalData.type, d.data[modalData.index].id);
      } else {
        d.data.splice(modalData.index, 1)
        d.total = (d.total - 1)
        setDataList({ data: d })
      }
      handleClose()
    }

    setloadingSave(false)
  }
  
  const modalBody = () => {
    if (modalData !== null) {
      if (modalData.type === 'tag') {
        return (
          <form className="custom_form" ref={formTagRef}>
            <div className='form-group row'>
              <div className='col-lg-2 text-end'>
                <label className='form-label '>Tag :</label>
              </div>
              <div className='col-lg-10'>
                {tags !== null ? (
                  <Select
                    isClearable
                    options={tags}
                    defaultValue={saleTag !== null ? saleTag : ''}
                    onChange={(v) => setSaleTag(v)}
                    placeholder={"Select Sale Tag"}
                    className='select-color'
                  />
                ) : (
                  <div className="mt-2 text-white">
                    Please wait..
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      } else if (modalData.type === 'discard') {
        return (
          <form className="custom_form" ref={formDiscardRef}>
            <div className='form-group'>
              <div className='col-lg-12'>
                <Select
                  isClearable
                  options={discardOption}
                  value={discOption !== null ? discOption : ''}
                  onChange={(v) => setDiscOption(v)}
                  maxMenuHeight="180px"
                  placeholder={"Select Option"}
                  className='select-color'
                />
              </div>
            </div>
          </form>
        )
      } else if (modalData.type === 'more') {
        let d = _.cloneDeep(dataList.data)
        return (
          <>
            <div className='d-grid gap-2 d-md-block'>
              <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => window.open(`${process.env.REACT_APP_LMS_URL}learn/${d.data[modalData.index].id}/1`, '_blank')}>
                View LMS <BiLinkExternal />
              </button>
              <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => window.open(`${process.env.REACT_APP_WEB_LINK}courses/display/${d.data[modalData.index].slug}`, '_blank')}>
                Display <BiLinkExternal />
              </button>
              {
                checkPermission('perm_seo') && (
                  <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/seo/${d.data[modalData.index].id}`)}>
                    SEO
                  </button>
                )
              }
              {
                checkPermission('perm_update') && (
                  <>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/tag-update/${d.data[modalData.index].id}`)}>
                      Course Update Tagging
                    </button>
                  </>
                )
              }
            </div>
            <hr />
            <div className='d-grid gap-2 d-md-block'>

              {
                checkPermission('perm_update') && (
                  <>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/batch/${d.data[modalData.index].id}`)}>
                      Manage Batch
                    </button>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/update-category/${d.data[modalData.index].id}`)}>
                      Update Category
                    </button>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/update-summary/${d.data[modalData.index].id}`)}>
                      Update Text Info
                    </button>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/replace-author/${d.data[modalData.index].id}`)}>
                      Replace Author
                    </button>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2 me-2" onClick={() => navigate(`/course/transcribe/${d.data[modalData.index].id}`)}>
                      Transcribe Videos
                    </button>
                    <button type="button" class="btn-grad btn-secondary btn btn-primary mt-2" onClick={() => navigate(`/course/update-tier/${d.data[modalData.index].id}`)}>
                      Manage iOS Tier
                    </button>
                  </>
                )
              }
            </div>
          </>
        )
      }
    }
    return null;
  }

  const changeAction = (type, id) => {
    let cloneData = _.cloneDeep(dataList);
    cloneData.data.data.forEach((item) => {
      if (item.id === id) {
        if (type === 'status') {
          item[type] = (item[type] === 3 ? 2 : 3)
        } else {
          item[type] = !item[type];

        }
      }
    });
    setDataList(cloneData);
  }

  const genRow = (rowData, i) => {
    let val =
      params.id === '1' ? (
        <>
          <td><OrderBy id={rowData.id} field={'rank'} url={'post-course-rank'} name={rowData.name} rank={rowData.rank} list={dataList} setList={setDataList} /></td>

          <td> {rowData.item_id}</td>

          <td> {rowData.user ? rowData.user.first_name + ' ' + rowData.user.last_name : ''}</td>

          <td>{rowData.name ? rowData.name : ''}</td>

          <td>{rowData.course_subject ? rowData.course_subject.name : ''}</td>

          <td>{rowData.course_delivery_mode ? rowData.course_delivery_mode.name : ''}</td>

          <td>{rowData.course_language ? rowData.course_language.name : ''}</td>

          <td><button type="button" className="btn btn-link" onClick={() => handelAction(i, 'tag')}>{rowData.sale_tag !== null ? rowData.sale_tag : 'Add Sale Tag'}</button></td>

          <td>{rowData.offer_price ? rowData.offer_price : ''}</td>

        </>
      ) : (
        <>
          <td><OrderBy id={rowData.id} field={'rank'} url={'post-course-version-rank'} name={rowData.name} rank={rowData.rank} list={dataList} setList={setDataList} /></td>
          <td>{rowData.name ? rowData.name : ''}</td>
          <td>{rowData.user
            ? rowData.user.first_name + ' ' + rowData.user.last_name
            : ''}</td>
          <td>{rowData.create_date ? rowData.create_date : ''}</td>
        </>
      )
    return val
  }

  const handleCourse = v => {
    setCourseId(v !== null ? v.id : v)
  }

  const handelRefresh = () => {
    setCourseId(null)
    setSubject(null)
    setLanguage(null)
    setStatus(null)
    setIsActive(null)
    setIsAddedTo999(null)
    setIsCertificateAvailable(null)
    setIsPromoCourse(null)
    setIsFeaturedCourse(null)
    setStartDate(null)
    setEndDate(null)
    if (formRef.current) {
      formRef.current.reset();
    }
    if (courseRef.current) {
      courseRef.current.clearValue();
    }
    setRefresh(true)
  }

  const handleBack = () => {
    navigate('/course/list')
  }

  return (
    <div className='inner_right_section'>
      <div className='inner_right_content'>
        <div className='row'>
          <div className='col-lg-8'>
            <p className='section_header'>
              Manage Course /{' '}
              <small>{params.id === '1' ? 'List' : 'Approve'} {dataList.data !== undefined && dataList.data.total !== undefined ? " ( Total: " + dataList.data.total + " )" : ""}</small>
            </p>
          </div>

          <div className='col-lg-4 text-end'>
            <button className='admin_btn round_btn me-2' onClick={() => handleBack()}>
              <BiArrowBack />
            </button>
            <button className='admin_btn round_btn me-2' onClick={() => setShowSearch(!showSearch)}>
              <BiSearch />
            </button>
            <button
              className='admin_btn round_btn me-2'
              onClick={() => handelRefresh()}
            >
              <BiRefresh className={pageLoading ? 'icon-spin' : ''} />
            </button>
          </div>
          <div className='col-lg-12'>
            <hr />
          </div>
          {showSearch && (
            <div className='col-lg-8 '>
              <form ref={formRef} className='custom_form' onSubmit={(e) => search(e)}>
                <p className='form_header'>Search</p>
                <div className='search_box'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group row'>
                        <div className='col-lg-4 text-end'>
                          <label className='form-label'>Name :</label>
                        </div>
                        <div className='col-lg-8'>
                          <SearchableSelect
                            noOptionsMessage={'Type Course Name'}
                            loadOptions={getCourseSuggestion}
                            onSelect={handleCourse}
                            placeholder={'Select Course'}
                            itemRef={courseRef}
                          />
                        </div>
                      </div>
                      {params.id === '1' ? (
                        <>

                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>Subject :</label>
                            </div>
                            <div className='col-lg-8'>
                              {subjectData !== null ? (
                                <Select
                                  isClearable
                                  options={subjectData}
                                  getOptionLabel={(sub) => sub.name}
                                  getOptionValue={(sub) => sub.id}
                                  value={subject !== null ? subject : ''}
                                  onChange={(sub) => setSubject(sub)}
                                  placeholder={"Select Subject"}
                                  className='select-color'
                                />
                              ) : (
                                <div className="mt-2 text-white">
                                  Please wait..
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>Language :</label>
                            </div>
                            <div className='col-lg-8'>
                              {languageData !== null ? (
                                <Select
                                  isClearable
                                  options={languageData}
                                  getOptionLabel={(lan) => lan.name}
                                  getOptionValue={(lan) => lan.id}
                                  value={language !== null ? language : ''}
                                  onChange={(lan) => setLanguage(lan)}
                                  placeholder={"Select Language"}
                                  className='select-color'
                                />
                              ) : (
                                <div className="mt-2 text-white">
                                  Please wait..
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>Status :</label>
                            </div>
                            <div className='col-lg-8'>
                              {statusData !== null ? (
                                <Select
                                  isClearable
                                  options={statusData}
                                  value={status !== null ? status : ''}
                                  onChange={(sta) => setStatus(sta)}
                                  placeholder={"Select Status"}
                                  className='select-color'
                                />
                              ) : (
                                <div className="mt-2 text-white">
                                  Please wait..
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>Active :</label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={isActive !== null ? isActive : ''}
                                onChange={(b) => setIsActive(b)}
                                placeholder={"Select Active Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>999 Course :</label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={isAddedTo999 !== null ? isAddedTo999 : ''}
                                onChange={(b) => setIsAddedTo999(b)}
                                placeholder={"Select 999 Course Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>
                                Available Certificate :
                              </label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={isCertificateAvailable !== null ? isCertificateAvailable : ''}
                                onChange={(b) => setIsCertificateAvailable(b)}
                                placeholder={"Select Available Certificate Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>
                                Promo Course :
                              </label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={isPromoCourse !== null ? isPromoCourse : ''}
                                onChange={(b) => setIsPromoCourse(b)}
                                placeholder={"Select Promo Course Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>
                                Featured Course :
                              </label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={isFeaturedCourse !== null ? isFeaturedCourse : ''}
                                onChange={(b) => setIsFeaturedCourse(b)}
                                placeholder={"Select Featured Course Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>
                                Active In App :
                              </label>
                            </div>
                            <div className='col-lg-8'>
                              <Select
                                isClearable
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' }
                                ]}
                                value={activeInApp !== null ? activeInApp : ''}
                                onChange={(b) => setActiveInApp(b)}
                                placeholder={"Select Active In App Status"}
                                className='select-color'
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-lg-4 text-end'>
                              <label className='form-label'>Host Date :</label>
                            </div>
                            <div className='col-lg-8'>
                              <div className='row'>
                                <div className='col'>
                                  <DateTime
                                    closeOnSelect
                                    inputProps={{
                                      readOnly: true,
                                      required: true,
                                      name: "start_date",
                                      className: "not_readonly_control form-control",
                                    }}
                                    renderInput={(props) => {
                                      return <input {...props} value={startDate !== null ? props.value : ''} />
                                    }}
                                    value={startDate !== null ? startDate : ''}
                                    dateFormat="MMM, D, YYYY"
                                    timeFormat={false}
                                    onOpen={() => setStartDate(null)}
                                    onChange={(date) => setStartDate(date.toDate())}
                                  />
                                </div>
                                <div className='col'>
                                  <DateTime
                                    closeOnSelect
                                    inputProps={{
                                      readOnly: true,
                                      required: true,
                                      name: "end_date",
                                      className: "not_readonly_control form-control",
                                    }}
                                    renderInput={(props) => {
                                      return <input {...props} value={endDate !== null ? props.value : ''} />
                                    }}
                                    value={endDate !== null ? endDate : ''}
                                    dateFormat="MMM, D, YYYY"
                                    timeFormat={false}
                                    onOpen={() => setEndDate(null)}
                                    onChange={(date) => setEndDate(date.toDate())}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='col-lg-9 serch-align'>
                      <Button
                        variant='secondary'
                        className='btn-grad'
                        disabled={loadingSearch}
                        type='submit'
                      >
                        {loadingSearch ? (
                          <ImSpinner2 className='icon-spin' />
                        ) : (
                          'Search'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className='col-lg-12'>
            {pageLoading ? (
              <div className='blank_box'>
                <div className='blank_inner_box'>
                  <TailSpin color='#20273a' />
                </div>
              </div>
            ) : (
              <div className='table-responsive' style={{ overflowY: '' }}>
                <table className='table table-hover custom_table'>
                  <thead>
                    <tr>
                      {tableHeadings().map((ele, i) => (
                        <th key={i} onClick={() => sorting.field === ele && applySorting(ele, !sorting.ascending)}>
                          {ele}{" "}
                          {sorting.field === ele && (sorting.ascending ? <BsArrowDownShort style={{ cursor: 'pointer' }} /> : <BsArrowUpShort style={{ cursor: 'pointer' }} />)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(dataList).length <= 0 || dataList.data === undefined || dataList.data.data === undefined || dataList.data.length <= 0 || dataList.data.data.length <= 0 ? (
                      <tr>
                        <td className="text-center" colSpan={tableHeadings().length}>No Data Found</td>
                      </tr>
                    ) : (
                      dataList.data.data.map((courseData, index) => (
                        <tr key={index}>
                          {genRow(courseData, index)}
                          {params.id === '1' ? (
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant='light'
                                  size='sm'
                                  id='dropdown-basic'
                                >
                                  Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant='light'>




                                  {checkPermission('perm_update') && (
                                    <>
                                      <Dropdown.Item onClick={() => handelAction(index, 'is_need_clear_test')}>
                                        {courseData.is_need_clear_test ? 'Test not needed' : 'Test needed'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'certificate_available')}>
                                        {courseData.certificate_available ? 'Remove Certificate' : 'Add Certificate'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'is_999')}>
                                        {courseData.is_999 ? 'Remove from 999' : 'Add To 999'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'not_for_sale')}>
                                        {courseData.not_for_sale ? 'Remove from Promo Course' : 'Add To Promo Course'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'is_paid_extension')}>
                                        {courseData.is_paid_extension ? 'Remove from Paid Extension' : 'Add To Paid Extension'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'is_featured')}>
                                        {courseData.is_featured ? 'Remove from Featured' : 'Add To Featured'}
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handelAction(index, 'active_in_app')}>
                                        {courseData.active_in_app ? 'Remove from App' : 'Add To App'}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />

                                      <Dropdown.Item onClick={() => handelAction(index, 'is_active')}>
                                        {courseData.is_active ? 'Deactivate' : 'Activate'}
                                      </Dropdown.Item>

                                      <Dropdown.Item onClick={() => handelAction(index, 'status')}>
                                        {parseInt(courseData.status) === 3 ? 'Disapprove' : 'Approve'}
                                      </Dropdown.Item>

                                      <Dropdown.Item onClick={() => handelAction(index, 'is_available_stockedge_club')}>
                                        {courseData.is_available_stockedge_club ? 'Remove from SE Club' : 'Add in SE Club'}
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Divider />
                                  <Dropdown.Item onClick={() => handelAction(index, 'more')}>
                                    More
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          ) : (
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant='light'
                                  size='sm'
                                  id='dropdown-basic'
                                >
                                  Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant='light'>
                                  <Dropdown.Item>
                                    <span onClick={() => handelAction(index, 'approve')}>
                                      Approve
                                    </span>
                                  </Dropdown.Item>

                                  <Dropdown.Item>
                                    <span onClick={() => handelAction(index, 'discard')}>
                                      Discard
                                    </span>

                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {Object.keys(dataList).length > 0 && dataList.data.links !== undefined &&
                  parseInt(dataList.data.last_page) > 1 && (
                    <Pagination size="sm">
                      {dataList.data.links.map((pageLink, pageLinkIndex) => (
                        <Pagination.Item
                          onClick={() => pageChange(pageLink.label)}
                          key={pageLinkIndex}
                          active={pageLink.active}
                        >
                          {pageLinkIndex === 0 ? (
                            <BsArrowLeft />
                          ) : pageLink.label === "Next &raquo;" ? (
                            <BsArrowRight />
                          ) : (
                            <>{pageLink.label} </>
                          )}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        size={modalData !== null ? modalData.size : 'sm'}
        show={showModal}
        header={modalData !== null && modalData.header}
        onHide={handleClose}
        body={modalBody()}
        onClose={handleClose}
        onSubmit={(e) => handelSubmit(e)}
        isform={modalData !== null && modalData.isform}
        loadingsave={loadingSave}
      />
    </div>
  )
}

export default CourseList;

