import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { toasterTypes } from "../../redux/types/toasterTypes";

const ThemeToaster = () => {

	const toastState = useSelector(state=>state.toastState)
	const dispatch = useDispatch()
	
	const closeToast = ()=>{
		dispatch({
			type: toasterTypes.CLOSE_TOAST
		})
	}
	if (toastState.show_toast) {
		switch (toastState.toast_type) {
			case "success":
				toast.success(toastState.toast_msg, {
					toastId: "success",
					onClose: closeToast
				});
				break;
			case "error":
				toast.error(toastState.toast_msg, {
					toastId: "error",
					onClose: closeToast
				});
				break;

			default:
				toast.info(toastState.toast_msg, {
					toastId: "info",
					onClose: closeToast
				});
				break;
		}
	}



	

	return (
		<ToastContainer
			position="bottom-left"
			autoClose={5000}
			hideProgressBar={true}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="dark"
		/>
	);
};

export default ThemeToaster;
